import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_3 from '../../images/leistungen/img3.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Klassische Schmerztherapie | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet eine klassische Schmerztherapie mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Klassische Schmerztherapie"} subtitle={"Individuelle ganzheitliche Therapie in Bochum"} frontImage={img_leistung_3}></HeadlineWithImage>
          </Col>
        </Row>
          Das von der Weltgesundheitsorganisation (WHO) entwickelte Stufenschema zur medikamentösen Behandlung von Schmerzen bildet die Grundlage jeder individuellen Schmerztherapie.
          <br/>
          <br/>
          Bei chronischen Schmerzen reicht dieses zumeist nicht. Hier gilt es, ein sinnvolles Konzept aus vielschichtigen Behandlungen (Medikamente/physikalische und psychologische Verfahren) einzuleiten.
          <br/>
          <br/>
          Ich setze verstärkt auf ganzheitliche Therapien mit sanften, schonenden Verfahren auch aus der Naturheilkunde, um Ihnen nicht nur den Schmerz zu nehmen, sondern auch den Organismus wieder ins Gleichgewicht zu bringen und Selbstheilungskräfte anzuregen.
          <br/>
          <br/>
          <SimpleModernHeadline h3={true}>Wichtige Ansatzpunkte der „Ganzheitlichen Schmerztherapie“ sind:</SimpleModernHeadline>
          <ul>
            <li>Muskeln und Faszien (z.B. Triggerpunktbehandlung)</li>
            <li>Positive Beeinflussung des Schmerzzentrums (u.a. über Akupunktur und Mesotherapie)</li>
            <li>Optimierung der Lebensweise mit Bewegung und Entspannung</li>
            <li>Vitalstoffoptimierung (Mikronährstoff-, Orthomolekulare Medizin)</li>
          </ul>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
